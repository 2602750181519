import styled from 'styled-components';
import {color, theme} from "../constants";

export const Canvas = styled.div`
    //box-shadow: 0 700px 0 #b79778, 0 0 30px #b79778 inset;
    //background: #faf8f4;
`;

export const Leaf = styled.div`
    background-color: ${theme.mainColor};
    width: 200px;
    height: 140px;
    border-radius: 40px;
`;

export const Member = styled.div`
    display: flex;
    height: 70px;
    width: 200px;
`;

export const MemberPhoto = styled.div<{ photo: string; }>`
    margin: 5px;
    border-radius: 100px;
    min-width: 60px;
    width: 60px;
    height: 60px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    text-transform: uppercase;
    background: url(${(props) => (props.photo)}) center;
    background-size: 100%;
`;

export const MemberInfo = styled.div`
    color: ${color.memberMain};
    margin: 5px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    text-transform: uppercase;
`;
