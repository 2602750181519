import {IRestClient} from "../util/rest/IRestClient";
import {RestClient} from "../util/rest/RestClient";
import {Include} from "./model/Include";
import {IMemberApi} from "./IMemberApi";
import {IMember} from "./model/IMember";
import {IReplaceRelativesRequest} from "./request/IReplaceRelativesRequest";

export class MemberApi implements IMemberApi {
    constructor(
        private restClient: IRestClient = new RestClient(),
    ) {
    }

    async createMember(member: IMember): Promise<IMember> {
        return this.restClient.get<IMember>(`/api/v1/members`);
    }

    async replaceRelatives(member: IMember): Promise<IMember> {
        let request: IReplaceRelativesRequest = {
            parents: member.parents,
            children: member.children
        };
        return this.restClient.updatePartially<IReplaceRelativesRequest, IMember>(`/api/v1/members/${member.id}/relatives`, request);
    }

    async updateMember(member: IMember): Promise<IMember> {
        return this.restClient.get<IMember>(`/api/v1/members`);
    }

    async getMember(memberId: number, includes?: Include[]): Promise<IMember> {
        return this.restClient.get<IMember>(`/api/v1/members/${memberId}`, {
            includes
        });
    }

    async getMembers(memberIds?: number[], includes?: Include[]): Promise<IMember[]> {
        return this.restClient.get<IMember[]>(`/api/v1/members`, {
            memberIds: memberIds,
            includes: includes
        });
    }

    async getMembersByLeaf(leafId: number, includes?: Include[]): Promise<IMember[]> {
        return this.restClient.get<IMember[]>(`/api/v1/members/leaves/${leafId}`, {
            includes
        });
    }

    async getMembersByNearestBirthday(date: Date, includes?: Include[]): Promise<IMember[]> {
        return this.restClient.get<IMember[]>(`/api/v1/members/birthday`, {
            date: date.toISOString().split('T')[0],
            includes
        });
    }

    async findMembers(query: string, includes?: Include[], limit?: number): Promise<IMember[]> {
        return this.restClient.get<IMember[]>(`/api/v1/members/search`, {
            query: query,
            limit: limit,
            includes: includes
        });
    }

    async removeMember(memberId: number): Promise<IMember> {
        return this.restClient.get<IMember>(`/api/v1/members/${memberId}`);
    }
}