import styled from 'styled-components';
import confettiGif from '../../img/confetti.gif';
import {color, theme} from "../constants";

export const Card = styled.div<{ confetti: boolean; }>`
  position: relative;
  transition: 0.24s all;
  transform: perspective(0) rotateY(0) translateX(0) scale(1, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 328px;
  height: 452px;
  border-radius: 4px;
  box-shadow: 0 24px 40px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid whitesmoke;
  overflow: hidden;
  cursor: pointer;
  filter: blur(0);
  background: #fff;
  margin: 10px;
  
  ${props => {
    if (props.confetti) {
      return `
        background-image: url(${confettiGif});
        background-size: contain;
      `;
    }
  }}
`;

export const CardPhoto = styled.div<{ photo: string, isLoading: boolean; }>`
  width: 100%;
  height: 284px;
  background: url(${(props) => (props.photo)}) center;
  background-size: 100%;

  ${props => {
    if (props.isLoading) {
      return `
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.16);
      `;
    }
  }}
`;

export const CardTitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  letter-spacing: 2px;
`;

export const Name = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  letter-spacing: 2px;
  color: ${color.memberMain};
  margin-top: 45px;
`;

export const Info = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${color.memberSecond};
  margin-top: 8px;
`;
