import React, {useState} from 'react';
import {Select} from 'antd';
import {MemberApi} from "../../api/MemberApi";
import {DefaultOptionType} from "rc-select/lib/Select";
import {IMember} from "../../api/model/IMember";

const {Option} = Select;

export const SelectMember: React.FC<{ onSelect?: (member: IMember) => void }>
    = ({onSelect = (member: IMember) => {}}) => {

    const memberApi = new MemberApi()
    const [options, setOptions] = useState<DefaultOptionType[]>([]);

    function onSearch(query: string) {
        memberApi.findMembers(query)
            .then((data) => {
                let suggestedOptions = data.map(member => {
                    return {
                        member: member,
                        value: member.id,
                        label: `${member.name} ${member.surname}`,
                        img: member.thumbnail ? `/files/${member.thumbnail}` : process.env.PUBLIC_URL + 'images/no-photo.png',
                        birthYear: member.birthday ? `(${new Date(member.birthday * 1000).getFullYear()} г)` : ""
                    }
                })
                setOptions(suggestedOptions);
            });
    }

    return <Select
        showSearch
        style={{width: "100%"}}
        placeholder="Search to Select"
        optionLabelProp="label"
        filterOption={false}
        onSearch={onSearch}
        onClick={() => onSearch('')}
        onSelect={(value, option) => onSelect(option.member)}
    >
        {options.map((option) => (
            <Option key={option.value} value={option.value} label={option.label} member={option.member}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div
                        style={{
                            background: `url(${option.img}) center`,
                            backgroundSize: '100%',
                            width: 25,
                            height: 25,
                            marginRight: 8,
                            borderRadius: '50%'
                        }}
                    />
                    {option.label} {option.birthYear}
                </div>
            </Option>
        ))}
    </Select>
};
